<script>
import mapDb1 from "@/assets/test-data/map-db-category-1.json";
import mapDb1Conf from "@/assets/test-data/map-db-category-1-conf.json";
import pyramidDb1 from "@/assets/test-data/pyramid-db-category-4.json";
import pyramidDb1Conf from "@/assets/test-data/pyramid-db-category-4-conf.json";
import barDb1 from "@/assets/test-data/bars-db-category-3.json";
import barDb1Conf from "@/assets/test-data/bars-db-category-3-conf.json";
import lineDb1 from "@/assets/test-data/line-db-category-4.json";
import lineDb1Conf from "@/assets/test-data/line-db-category-4-conf.json";
import linesDb1Conf from "@/assets/test-data/lines-db-category-5-conf.json";
import MapCategory from "@/components/MapCategoryGraph.vue";
import BarCategory from "@/components/BarsCategoryGraph.vue";
import LineCategory from "@/components/LineCategoryGraph.vue";
import LinesCategory from "@/components/LinesCategoryGraph.vue";
import PyramidCategory from "@/components/PyramidCategoryGraph.vue";

export default {
  setup(){
    return {
      mapDb1,
      mapDb1Conf,
      barDb1,
      barDb1Conf,
      lineDb1,
      lineDb1Conf,
      linesDb1Conf,
      pyramidDb1,
      pyramidDb1Conf
    }
  },
  components : {
    MapCategory,
    BarCategory,
    LineCategory,
    LinesCategory,
    PyramidCategory
  }
}
</script>

<template>
<section>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h1>This is the graph test page :)</h1>
      </div>
      <div class="col-sm-8 offset-sm-2 mb-4 pb-4">
        <pyramid-category :data="pyramidDb1" :config="pyramidDb1Conf[0]" />
      </div>
      <div class="col-sm-8 offset-sm-2 mb-4 pb-4">
        <lines-category :data="lineDb1" :config="linesDb1Conf[0]" />
      </div>
      <div class="col-sm-8 offset-sm-2 mb-4 pb-4">
        <line-category :data="lineDb1" :config="lineDb1Conf[0]" />
      </div>
      <div class="col-sm-8 offset-sm-2 mb-4 pb-4">
        <bar-category :data="barDb1" :config="barDb1Conf[0]" />
      </div>
      <div class="col-sm-8 offset-sm-2 mb-4 pb-4">
        <map-category :data="mapDb1" :config="mapDb1Conf[0]" />
      </div>
    </div>    
  </div>
</section>
</template>
